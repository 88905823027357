.header {
  width: 100vw;
  @media (min-width: 750px) {
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 12px 20px;
  text-align: left;
  background-color: var(--bg-light-color);
  height: 42px;
  box-sizing: content-box;

  @media (min-width: 750px) {
    padding: 12px 0 12px 10vw;
  }

  .logo {
    height: 42px;
    width: auto;
  }
}

.breadcrumbs {
  padding-left: 20px;
  margin-top: 100px;

  @media (min-width: 750px) {
    padding-left: 10vw;
    margin-top: 130px;
  }
}
