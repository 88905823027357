.wrapper {
  align-items: center;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  @media (max-height: 640px) {
    position: static;
  }

  .footer {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 68px;
    font-size: 12px;
    color: var(--text-light-color);
    background-color: var(--bg-blue-color);

    @media (min-width: 750px) {
      padding: 12px 0 17px;
    }

    &Links {
      flex-direction: row !important;
      max-width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 40px;
      row-gap: 4px;
    }

    &Link {
      color: var(--text-light-color);
      position: relative;

      &:not(:last-child):after {
        content: '';
        background-color: #6478e1;
        width: 1px;
        height: 17px;
        position: absolute;
        margin-left: 20px;
      }

      @media (max-width: 510px) {
        &:nth-child(2):after {
          background-color: transparent;
        }
      }
      @media (max-width: 355px) {
        &:nth-child(1):after {
          background-color: transparent;
        }
      }
    }
  }
}
