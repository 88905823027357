.breadcrumbs {
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
  color: var(--text-dark-color);
  @media (max-width: 700px) {
    padding-top: 0;
  }

  a {
    color: var(--text-dark-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  &Current {
    color: var(--text-blue-color);
  }
}
