:root {
  --text-dark-color: #1b1c20;
  --text-light-color: #ffffff;
  --text-blue-color: #3756f4;
  --bg-light-color: #ffffff;
  --bg-blue-color: #3951cc;
  --bg-main-color: #fafafa;
  --error-color: #e84420;
}

* {
  font-family: 'Poppins', sans-serif;
  color: var(--text-dark-color);
  text-align: center;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Poppins', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: var(--bg-main-color);
  overflow-x: clip;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Avenir', Tahoma, Geneva, Verdana, sans-serif;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
}

img {
  width: 100%;
}

img {
  pointer-events: none;
}

ul,
ol,
ul li,
ol li {
  text-align: left;
}
