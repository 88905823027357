.container {
  article {
    background: #e6e8ff;
  }

  iframe {
    width: 100%;
    height: 420px;
    @media (max-width: 1200px) {
      height: 300px;
    }
    @media (max-width: 1200px) {
      height: 250px;
    }
  }
  p {
    text-align: left;
    margin: 16px 0;
    @media (max-width: 700px) {
      margin: 12px 0;
    }
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
    @media (max-width: 700px) {
      margin: 0;
      font-size: 16px;
    }
  }

  figure {
    margin: 0;
    margin-bottom: 20px;
  }
  article {
    margin-top: 24px;

    @media (max-width: 700px) {
      margin-top: 4px;
    }
    strong:first-of-type {
      margin-bottom: 12px;
      width: 85%;
      display: block;
    }

    p:first-of-type {
      width: 95%;
      margin: 0;
    }
    content: '';
    background-repeat: no-repeat;
    background-position: 95% 20px;
    padding: 24px 32px;
    border-radius: 20px;
  }
  td {
    border: 1px solid #000000;
    padding: 0.5em;
  }

  table {
    border-collapse: collapse;
  }
}
