.bgImage {
  position: absolute;
  overflow: hidden;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.bgImageRed {
  width: 500px;
  top: 15%;
  left: 0;
  img {
    margin-left: -70%;
  }

  @media (max-width: 750px) {
    width: 350px;
    img {
      margin-left: -90%;
    }
  }
}
.bgImageBlue {
  width: 400px;
  bottom: 15%;
  right: 0;

  img {
    margin-right: -70%;
  }

  @media (max-width: 750px) {
    width: 300px;
    bottom: 25%;
  }
}

.container {
  margin-top: 64px;
  // overflow: hidden;

  @media (min-width: 750px) {
    margin-top: 120px;
  }
  @media (min-width: 750px) and (max-height: 750px) {
    margin-top: 100px;
  }

  // &::before,
  // &::after {
  //   position: absolute;
  // }
  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: -200px;
  //   width: 500px;
  //   height: 500px;
  //   background-image: url('../../static/images/ellipse_red.webp');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   z-index: 1;
  // }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   right: -150px;
  //   width: 400px;
  //   height: 400px;
  //   background-image: url('../../static/images/ellipse_blue.webp');
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   z-index: 1;
  // }
}

.main {
  &Image {
    max-width: 100%;
    margin: 0 auto;

    @media (min-width: 500px) {
      max-width: 480px;
    }
  }

  &Title {
    margin: 0 auto;

    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;

      @media (min-width: 750px) {
        line-height: 36px;
      }
    }

    @media (min-width: 500px) {
      max-width: 458px;
    }
  }
}

.linkContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 700px) {
    gap: 12px;
  }
}

.test {
  min-height: 154px;
  padding: 28px 28px 32px 28px;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  text-decoration: none;
  display: block;
  transition: all 0.2s ease-in;
  @media (max-width: 1200px) {
    padding: 20px;
    min-height: 122px;
  }
  @media (max-width: 700px) {
    padding: 20px 15px 15px 15px;
    border-radius: 20px;
    min-height: 140px;
  }

  @media (min-width: 750px) {
    &:hover {
      background-color: #d4e1fb;

      button {
        background-color: #3756f4;
        color: white;
      }
    }
  }
  @media (max-width: 750px) {
    &:active {
      background-color: #d4e1fb;

      button {
        background-color: #3756f4;
        color: white;
      }
    }
  }

  &Wrapper {
    width: 100%;
    height: 100%;
    gap: 16px;
    align-items: flex-start;
  }

  &Title {
    margin: 0 0 12px 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    @media (max-width: 750px) {
      font-weight: 600;
      font-size: 15px;
      flex: 1 0 auto;
    }
  }
}
